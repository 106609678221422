import { forkJoin, Observable, Subscription } from 'rxjs';
import { ProjectGlobalInfoModel } from 'src/app/shared/model/project-global-info.model';
import { AdminManagementService } from '@projects/user-management/src/app/user/service/admin-management.service';
import { CreateProjectService } from '@projects/create-project/src/app/create-project/create-project.service';
import { Component, OnInit, Inject, ViewEncapsulation, Renderer2, OnDestroy, Pipe } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SharedService } from '../../services/shared.service';
import { CommonDialogueBoxData, DELETE_ALL_NOTIFICATIONS, EC_TITLE, SO_TITLE, TestAI_TITLE, validationMessages, DOWNLOAD_REPORTS, REPORT_DEFAULT_MIN_DATE, REPORT_DEFAULT_MAX_DATE, SELECT_TYPE_STATUS } from '../../constants/common-dialogue-box';
import { KeyValue, Location } from '@angular/common';
import { Router } from '@angular/router';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';
import { PassGlobalInfoService } from '../../services/pass-project-global-info.service';
import { CryptUtilService } from '../../services/crypt-util.service';
import { HttpHeaders } from '@angular/common/http';
import { MyProjectsService } from '@projects/workspace/src/app/my-projects/my-projects.service';
import { SitemapService } from '@projects/sitemap/src/app/sitemap/sitemap.service';
import { UserRoleAssociationDetails } from '../../model/project-global-info.model';
import { PROJECT_USERS_DELETE } from './common-dialogue-box.constants';
import { EC_SYNC_INFO_POPUP, EC_SYNC_STATUS_COLOR_CODE_MAP } from '@projects/psg/src/app/core/utility/constants';
import { TEST_AI_PROVISION_TITLE } from '@projects/trainstops/src/app/core/utility/constants'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivitiesGlobalContextService } from '@projects/trainstops/src/app/feature/activities/activities-global-context.service';
import { formatDate } from '@angular/common';
import { LANGUAGE_DATA } from '../../../../../projects/psg/src/app/core/utility/constants';
import { FormControl, UntypedFormGroup ,UntypedFormBuilder} from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';
@Component({
  selector: 'app-common-dialogue-box',
  templateUrl: './common-dialogue-box.component.html',
  styleUrls: ['./common-dialogue-box.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ],
  encapsulation: ViewEncapsulation.None
})
export class CommonDialogueBoxComponent implements OnInit, OnDestroy {

  userInfo: UserRoleAssociationDetails;
  hideApplyBtn: boolean = false;
  hideCancelBtn: boolean = false;
  Test_AI_PROVISION_TITLE = TEST_AI_PROVISION_TITLE;
  dialogMessage: any;
  userPrefDataFound: boolean = false;
  noPublishedPackage: boolean = false;
  noOfferings: boolean = false;
  isDataFetchingInProgress: boolean = false;
  showLoader : boolean = false;
  noPublishedPkgMsg: any;
  noOfferingsMsg: any;
  projectGlobalInfo: ProjectGlobalInfoModel = new ProjectGlobalInfoModel();
  globalSubscription: Subscription[] = [];
  parentId: number;
  ecTitle = EC_TITLE;
  soTitle = SO_TITLE;
  testAITitle = TestAI_TITLE;
  EC_SYNC_INFO_POPUP = EC_SYNC_INFO_POPUP;
  EC_SYNC_STATUS_COLOR_CODE_MAP = EC_SYNC_STATUS_COLOR_CODE_MAP;
  reportPollingTimer: any;
  languageId: number = 1;
  msTeamsChannelTypes:{id:string ,name: string,selected :boolean}[] = [];
  myControl = new FormControl();
  msTeamsChannels: string[] = [];
  groupChannelId :string;
  ishidden:boolean = false;
  isDropdownOpen:boolean = true;
  ownedChannels:   {id:string ,channel: string}[] = [];
  filteredChannels: string[] = [];
  channelType:string;
  selectedMsChannel:string;
  channelForm: UntypedFormGroup;
  isChannelSelected:boolean = true;
  projectPackagesAndStatus = {
    typeOfReports: { data: [], currentSelected: '' },
    dateRangeOfReports: { data: ['All', 'Custom date'], currentSelected: 'All',tooltips: ['All the projects exist in ascend','Reports will be generated with projects which has start date within this date range'] },
    statusOfProjects: {
      allCompleted: false,
      projectStatus: []
    },
    packages: {
      allCompleted: true,
      packageList: []
    },
    selectedStartDate: '',
    selectedEndDate: '',
    enableDownloadButton: false,
    currentValidationError: "",
    minDate: REPORT_DEFAULT_MIN_DATE,
    maxDate: REPORT_DEFAULT_MAX_DATE,
    startDate: {minDate:  REPORT_DEFAULT_MIN_DATE, maxDate: REPORT_DEFAULT_MAX_DATE},
    endDate: {minDate: REPORT_DEFAULT_MIN_DATE, maxDate: REPORT_DEFAULT_MAX_DATE},
    currentPackage: ['projectPackagesAndStatus.packages.allCompleted'],
    currentStatus: ['projectPackagesAndStatus.statusOfProjects.allCompleted']
  }
  masterUserRoledata = [];
  constructor(
    public dialogRef: MatDialogRef<CommonDialogueBoxComponent>,
    private sharedService: SharedService,
    private userPreference: UserPreferenceService,
    public router: Router,
    public renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: CommonDialogueBoxData,
    private location: Location,
    private globalData: PassGlobalInfoService,
    private cryptUtilService: CryptUtilService,
    private myProjectsService: MyProjectsService,
    public createProjServ: CreateProjectService,
    private sitemapService: SitemapService,
    private adminManagementService: AdminManagementService,
    public globalContext: ActivitiesGlobalContextService,
    private formBuilder: UntypedFormBuilder,
  ) {
    // this.userPreference.getUserPrefData().subscribe((data) => {
    //   this.data.userPrefPackage = data.packageName;
    //   this.data.userPrefFunction = data.functionName;
    // });
    this.renderer.removeClass(document.body, 'cdk-top');
  }

  ngOnInit() {
    this.msTeamsChannelTypes.push({id:"1",name: 'Shared.CommonDialogBox.DeliveryTool.Dropdown.NewChannelSite',selected:true}) ;
    this.msTeamsChannelTypes.push({id:"2",name: 'Shared.CommonDialogBox.DeliveryTool.Dropdown.ExistChannelSite',selected:false}) ;
    this.userPreference.getLanguageId().subscribe(id=>{
      this.languageId =id;
    })
    this.globalSubscription.push(this.globalData.share.subscribe((info)=>{
      this.userInfo = info?.userRoleInfo;
     ///this.userInfo.role.isPackageAdmin = true;
    }));

    this.dialogRef.disableClose = true;
    if (this.data.from == "USER_PREFERENCES" || this.data.from == DOWNLOAD_REPORTS) {
      if ("router.url == '/welcome'") {
        this.dialogMessage = this.data.messageText;
      }
      forkJoin({result: this.userPreference.getUserPreferencesData(this.data.userId), res: this.userPreference.getUserPackageOfferings(this.data.userId, this.languageId)}).subscribe(({result, res})=> {
        if (result && result?.data) {
          result.data.forEach(element => {
            this.data.userPrefPackage = element.package;
            this.data.userPrefFunction = element.functions;
            this.parentId = element.parent_package_id;
            this.cryptUtilService.setItem("Function-Value-UserPref", this.data.userPrefFunction, "SESSION");
            this.cryptUtilService.setItem("Function-Value-UserPref-Initial", this.data.userPrefFunction, "SESSION");
            this.cryptUtilService.setItem("Package-Value-UserPref", this.data.userPrefPackage, "SESSION");
            this.cryptUtilService.setItem("Package-Value-UserPref-Initial", this.data.userPrefPackage, "SESSION");
        });
        }
        if(!res.data || res.data.length<1){
          // this.noOfferings = true;
          this.noOfferingsMsg= "";
        } else {
          this.noOfferings = false;
          this.data.activeOffering = res.data;
          if (this.data.from != DOWNLOAD_REPORTS)
          {
          this.data.selectedOffering = res?.data?.map(item =>{
            if(this.parentId && (item.packageId==this.parentId || item.package_id == this.parentId)){
                  this.data.userPrefOffering = item.packageName;
                }
            return item.packageName;
          })
        }
          else
          {
          this.sharedService.getPackagesAndProjectStatuses().subscribe(dt => {
            this.projectPackagesAndStatus.packages.packageList =  dt.offerings.map(_ => {return  {name: _.packageName,completed: true}})
            this.projectPackagesAndStatus.statusOfProjects.projectStatus = dt.projectStatuses.map(_ => {return {status: _,completed:_=="APPROVED" ? true :false,displayText: this.convertToCamelCase(_)}})
        })
          this.sharedService.getTypesOfReports().subscribe(_ => {
            _.forEach((__,ind) => {
                if (!ind)
                this.projectPackagesAndStatus.typeOfReports.currentSelected = __.requestType
                this.projectPackagesAndStatus.typeOfReports.data.push({name: __.requestType,active: true})
            })
          })
          }
        }

        if(this.data.userPrefOffering && this.data.userPrefPackage && this.data.userPrefFunction){
          this.hideMessage(true);
          this.userPrefDataFound = true;
        }
        if(this.parentId) this.getPackages("");
      }, (error) => {
        console.log(error);
        this.userPrefDataFound = false;
      });
    }
  }
  filterOwenedChannels(event: any): void {
    const filterValue = event?.target?.value?.toLowerCase();
    this.filteredChannels = this.msTeamsChannels?.filter(channel => channel?.toLowerCase()?.startsWith(filterValue));
  }
  getOwnedChannels(){
    this.isDropdownOpen = true;
    this.isDataFetchingInProgress = true;
    this.showLoader = true;
    this.filteredChannels = [];
    this.sharedService.getOwenedTeamChannels().subscribe((data) => {
      data.data.forEach(element => {
        this.ownedChannels.push({id:element.id,channel:element.displayName})
      });
      this.isDataFetchingInProgress = false;
      this.showLoader = false;
      this.msTeamsChannels = data.data.map(element => element.displayName);
      this.filteredChannels = data.data.map(element => element.displayName);
    });
  }
  onPanelFocus(text: any) {
    if (!text) {
      if (!this.filteredChannels)
        this.filteredChannels = this.filteredChannels;
      else
        this.isDropdownOpen = true;
    }
  }
  
  msChannelForm(){
    this.channelForm = this.formBuilder.group({
      msChannel: ['']
    });
  }
  onPanelClosed() {
    this.isDropdownOpen = false;
    const option = this.channelForm?.controls['msChannel']?.value;
    if (!this.msTeamsChannels.includes(option) ) {
      //this.channelForm?.controls['msChannel']?.setErrors(Validators.required)
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

isOptionSelected(option: string): boolean {
    let value = this.channelForm?.controls['msChannel']?.value;
    return false
 } 
  onMsChannelSelect(value: string)
  {
    
   this.groupChannelId = this.ownedChannels.find(_ => _.channel == value)?.id;
   if(this.groupChannelId) 
    {
      this.isChannelSelected = true;
      this.selectedMsChannel = this.groupChannelId;
    }
  }
  radioButtonChanged(e){
    let radioValue = e.value
     if(radioValue =="2"){
       this.ishidden = true;
       this.isChannelSelected = false;
       this.channelType="2"
     }
     else{
       this.ishidden = false;
       this.isChannelSelected = true;
       this.channelType="1"
     }
  }
  getPackages(offering) {
    if(offering){
      this.data.userPrefFunction= null;
      this.data.userPrefPackage = ""
      this.data.selectedFunctions = null;
      this.data.activeOffering.filter(item => {
        if(item.packageName === offering) {
          this.parentId = item.packageId;
        }
      });
    }
    this.userPreference.getUserPreferences(this.parentId).subscribe(response => {
      if (!response.data || response.data.length == 0) {
        this.noPublishedPackage = true;
        this.noPublishedPkgMsg =
          "No Package Method available for selection. Please publish a Package Method.";
          this.hideApplyBtn = true;
          this.data.selectedPackage = []
      } else {
        this.noPublishedPackage = false;
        if(response.data) {
        this.data.activePackages = response?.data;
        this.data.selectedPackage = response.data?.map(function (item) {
          return {name : item.name, display_nm: item.display_nm};
        });
      }
      if(this.data.selectedPackage.length==1) {
        this.data.userPrefPackage =  this.data.selectedPackage[0]?.name;
        this.getReleventFunctions(this.data.userPrefPackage);
      }
      if (this.userPrefDataFound && this.data.userPrefPackage && !this.noPublishedPackage) {
        this.getReleventFunctions(this.data.userPrefPackage);
      } else if(!this.data?.selectedPackage?.length) {
        this.userPrefDataFound = false;
      }
      if(!this.data?.userPrefPackage && !this.noPublishedPackage){
        this.hideApplyBtn = false;
      }
    }
    }, (error) => {
      console.log('error');
    });
  }
  closePopup() {
    this.dialogRef.close();
  }
 closeMSChannelPopup(){
  let teamsData={channelType:this.channelType,groupId:this.selectedMsChannel}
  return this.dialogRef.close(teamsData)
 }
  close() {
    if(this.data.from == PROJECT_USERS_DELETE)
      return this.dialogRef.close(false);
    else if(this.data.from == DELETE_ALL_NOTIFICATIONS) {
      return this.dialogRef.close(true);
    }

    if (this.data.from == 'CREATE PROJECT') {
      this.location.back();
    } else if (this.data.from == 'GENERATE SCOPE') {
      this.router.navigate(['/project/list']);
    } else if (this.noPublishedPackage && this.router.url =='/welcome') {
      this.router.navigate(['/workspace/my-projects']);
    }

    this.dialogRef.close(true);
  }

  discard() {
    if (this.data.from == 'CREATE_PROJECT' && this.data?.identifier == 'Unsaved Project') {

      if (this.data.from == 'CREATE_PROJECT' && this.data.tabChange == 1) {
        this.createProjServ.setCreateFormDetail(undefined);
        this.router.navigate(['/workspace/my-projects']);
        this.dialogRef.close(true);
      } else if (this.data.from == 'CREATE_PROJECT' && this.data.tabChange == 2) {
        this.createProjServ.setCreateFormDetail(undefined);
        this.createProjServ.getNavigationName.next(2);
        this.dialogRef.close(true);
      } else if (this.data.from == 'CREATE_PROJECT' && this.data.tabChange == 3) {
        this.createProjServ.setCreateFormDetail(undefined);
        this.createProjServ.getNavigationName.next(3);
        this.dialogRef.close(true);
      } else if (this.data.from == 'CREATE_PROJECT' && this.data.tabChange == 'exploreMode') {
        this.createProjServ.setCreateFormDetail(undefined);
        this.createProjServ.getNavigationName.next('exploreMode');
        this.dialogRef.close(true);
      } else if (this.data.from == 'CREATE_PROJECT' && this.data.tabChange == 'siteMap') {
        this.createProjServ.setCreateFormDetail(undefined);
        this.createProjServ.getNavigationName.next('siteMap');
        this.dialogRef.close(true);
      } else if (this.data.from == 'CREATE_PROJECT' && this.data.tabChange == 'learnAbtAscend') {
        this.createProjServ.setCreateFormDetail(undefined);
        this.createProjServ.getNavigationName.next('learnAbtAscend');
        this.dialogRef.close(true);
      } else if (this.data.from == 'CREATE_PROJECT' && this.data.tabChange == 'home') {
        this.createProjServ.setCreateFormDetail(undefined);
        this.createProjServ.getNavigationName.next('home');
        this.dialogRef.close(true);
      } else {
        this.createProjServ.setCreateFormDetail(undefined);
        this.router.navigate(['/workspace/my-projects']);
        this.dialogRef.close(true);
      }
    } else {
      this.dialogRef.close(false);
    }
  }

  apply(packageSelected, functionSelected, offeringSelected) {
    let selectedPackageId, selectedFunctionId, selectedParentPackageId, display_nm;
    this.data.activePackages?.forEach(packageName => {
      if (packageName.name == packageSelected) {
        selectedPackageId = packageName.package_id;
        display_nm = packageName.display_nm;
      }
    })
    
    selectedParentPackageId = this.parentId;
    this.data.activeFunctions?.forEach(functionName => {
      if (functionName.service_name == functionSelected) {
        selectedFunctionId = functionName.id;
      }
    })
    let obj = {
      userId: this.data.userId,
      packageId: selectedParentPackageId,
      methodId: selectedPackageId,
      functionId: selectedFunctionId ? selectedFunctionId: null,
    }
    this.userPreference.saveUserPreferences(obj).subscribe(datas => {
      if (datas.status == 201) {
        this.sendPackageData(packageSelected);
        this.sendFunctionData(functionSelected);
        this.sendPackageIdData(selectedPackageId);
        this.sendFunctionIdData(selectedFunctionId);
        this.sendParentPackageData(offeringSelected);
        this.sendParentPackageIdData(this.parentId);
        this.cryptUtilService.setItem('Previous-Session-Package-Selected-IIDR-Explore', packageSelected, 'SESSION');
        this.cryptUtilService.setItem('Previous-Session-Function-Selected-IIDR-Explore', functionSelected, 'SESSION');
        this.cryptUtilService.setItem('Previous-Session-Offering-Selected-IIDR-Explore', offeringSelected, 'SESSION');
        this.cryptUtilService.setItem('Previous-Session-Taxonomy-Selected-IIDR-Explore', null, 'SESSION');
        this.userPreference.sendTaxonomyIdData(null);
        this.cryptUtilService.setItem("function-value-selected", functionSelected, "SESSION");
        forkJoin({ response: this.adminManagementService.getUser(this.data.userId, this.languageId), result: this.myProjectsService.getMasterUserRole(LANGUAGE_DATA.ENGLISH) }).subscribe(({ response, result }) => {
          this.masterUserRoledata = result?.data;
          if(response) {

          let userRoleInfoObj = {
            userEmail: response.userEmail,
            role: response?.role?.length && response?.role[0],
            menu: response.menu,
            package: response?.package?.length && response?.package,
            roleSubset: response?.roleSubset,
            accessObjects: response?.accessObjects,
            englishLanguage: this.masterUserRoledata.find(ele => {
              return ele?.id === response?.role?.[0]?.id
          })
        }

        this.projectGlobalInfo.userRoleInfo = userRoleInfoObj;
        this.globalData.updateGlobalData(this.projectGlobalInfo);
      }
        });
        if(this.router.url =='/welcome')
        this.router.navigate(['/workspace/my-projects']);            
      if(this.router.url =='/workspace/my-projects')
        this.reloadMyProjects();

      }
      let useRPrefObj = {...obj, parentPackageId: this.parentId,
        parentPackageName: offeringSelected, display_nm: display_nm};
      this.userPreference.updateData(useRPrefObj);
      this.cryptUtilService.setItem("Session-Sitemap-Filter", "", "SESSION");
      this.cryptUtilService.setItem("Session-Sitemap-Filter-Attributes", null, "SESSION");
      this.cryptUtilService.setItem("Session-Sitemap-Filter-Amplifiers", null, "SESSION");
    });

    this.dialogRef.close(true);
    this.globalSubscription.push(
    this.globalData.exploreViewUserPref.subscribe(data => {
      if (data == 'IIDRView') {
        { this.router.navigate(['/iidr']); }
      }
    }));
    this.globalData.workspaceViewUserPref.subscribe(dataView => {
      if (dataView == 'WorkspaceView') { this.router.navigate(['/workspace/my-projects']); }

    });
  }
  
  reloadMyProjects() { 
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    }); 
  }
  closePopupFinalizeSprint() {
    this.dialogRef.close(false);
  }
  performAction() {
    this.dialogRef.close('action');
  }

  originalOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
    return 0;
  }

  getActiveFunctionsData() {
    this.hideApplyBtn = false;
    //this.data.messageText = this.dialogMessage;
    if ("router.url == '/welcome'") {
      this.hideCancelBtn = false;
    }
  }

  getReleventFunctions(packageName) {
    this.data.selectedFunctions = [];
    let packageId;
    this.data.activePackages.filter(function (item) {
      if (packageName == item.name) {
        packageId = item.package_id;
      }
    });
    this.userPreference.getUserPackageFunction(packageId)
      .subscribe((data) => {
        this.data.activeFunctions = data.data;
        let newScopes = [];
        if (data) {
          data.data.forEach(element => {
            newScopes.push(element.service_name)
          });
        }
        this.data.selectedFunctions = newScopes;
        if(this.data.selectedFunctions.includes(this.data.userPrefFunction))
        this.hideApplyBtn = true;
      }, (error) => {
        this.data.selectedFunctions = this.data.selectedFunctions;
      })
    //  }
    this.getActiveFunctionsData();
  }

  hideMessage(functionName) {
    if (functionName) {
      this.data.messageText = '';
      this.hideApplyBtn = true;
      if ("router.url == '/welcome'") {
        this.hideCancelBtn = true;
      }
    }
  }

  sendPackageData(p: any) {
    this.userPreference.sendPackageData(p);
  }

  sendFunctionData(f: any) {
    this.userPreference.sendFunctionData(f);
  }

  sendParentPackageData(o: any) {
    this.userPreference.sendOfferingData(o);
  }

  sendParentPackageIdData(oId: any) {
    this.userPreference.sendOfferingIdData(oId)
  }

  sendPackageIdData(pId: any) {
    this.userPreference.sendPackageIdData(pId);
  }

  sendFunctionIdData(fId: any) {
    this.userPreference.sendFunctionIdData(fId);
  }

  deleteProjectUser(projectId, userId, deleteUserId) {
    let obj = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        "projectId": projectId,
        "userId": userId,
        "deleteUserId": deleteUserId,
        "isMultipleDelete": this.data.isMultipleDelete
      }
    };

    this.myProjectsService.deleteProjectUser(obj).subscribe((response) => {
      this.dialogRef.close(true);
    })
  }

  softDeleteProject(projectId, userId) {
    let obj = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        "project_id": projectId,

        "user_id": userId
      }
    };
    const api_base_url = this.data.apiURL + '/Project/api/v1';
    this.myProjectsService.softDeleteProject(obj,api_base_url).subscribe((response) => {
      this.dialogRef.close('delete');
    })
  }

  deleteAdmin(userId) {
    let obj = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        "userId": this.data?.userId,
        "deleteUserId": this.data?.deleteAdminId
      }
    };
    this.myProjectsService.deleteAdminUser(obj).subscribe(adminData => {
      if (adminData["status"] == 200) {
      }
    });
    this.dialogRef.close(true);
  }

  ngOnDestroy() {
    this.globalSubscription?.forEach(sub => {
      sub.unsubscribe();
    })
    clearInterval(this.reportPollingTimer)
  }
  downloadReportOnClick(){
    const currentSelections = this.projectPackagesAndStatus;
    let body = {
        reportType: currentSelections.typeOfReports.currentSelected,       
        createdOn: new Date(),
        reportFilters: {
          offerings: (currentSelections.packages.packageList.filter(_ => _.completed == true)).map(_ => _.name),
          projectStatuses: (currentSelections.statusOfProjects.projectStatus.filter(_ => _.completed == true)).map(_ => _.status),
          startDate: currentSelections.selectedStartDate || new Date('02/01/2000 00:00:00Z'),
          endDate: currentSelections.selectedEndDate || new Date('12/31/9999 00:00:00Z'),
          ReportRequestId: 0,
        },
        requestedBy: this.data.userId,
        reportStatus : "New"
    } 
    this.sharedService.requestReportDownload(body).subscribe(_ => {
      this.scrollToTop()
      let reportRequestId = _
      this.reportPollingTimer =  this.sharedService.lookForReport(reportRequestId)
    },_ => {
      this.globalContext.setNewReport({active: true, status: 'ERROR'})
      this.scrollToTop()
    })
    this.globalContext.setNewReport({active: true,status: 'GENERATE'})
    this.dialogRef.close();
  }
  getTootipTextForExtra(selection: string)
  {
    let result = ''
    if (selection == SELECT_TYPE_STATUS)
    {
        result += 'Project Statuses\n'
        this.projectPackagesAndStatus.statusOfProjects.projectStatus.forEach(_ => {
            if (_.completed)
              result += `• ${_.displayText}\n`
        })
    }
    else
    {
      result += 'Offering/Package\n'
      this.projectPackagesAndStatus.packages.packageList.forEach(_ => {
          if (_.completed)
            result += `• ${_.name}\n`
      })
    }
    return result
  }
  validateReportInput()
  {
    const packageType = this.projectPackagesAndStatus.packages
    const statusType = this.projectPackagesAndStatus.statusOfProjects
    const dateType = this.projectPackagesAndStatus.dateRangeOfReports
    let packageValid = false,statusValid = false,dateValid = false
    //package validation
    if (packageType.allCompleted || (!packageType.packageList.every(dt => dt.completed == false)))
    {
    packageValid = true
    this.projectPackagesAndStatus.currentPackage = ['projectPackagesAndStatus.packages.allCompleted']
    }
    else
    {
      this.projectPackagesAndStatus.currentPackage = []
    }
    //Status validation
    if (packageValid && (statusType.allCompleted || (!statusType.projectStatus.every(dt => dt.completed == false))))
    {
       statusValid = true
       this.projectPackagesAndStatus.currentStatus = ['projectPackagesAndStatus.statusOfProjects.allCompleted']
    }
    else
    {
      this.projectPackagesAndStatus.currentStatus = []
    }

    //Date validation and setting min max date
    const currentStartDate = Object.prototype.toString.call(new Date((this.projectPackagesAndStatus.selectedStartDate as any)))
     const currentEndDate = Object.prototype.toString.call(new Date((this.projectPackagesAndStatus.selectedEndDate as any)))
      if ((packageValid && statusValid) && (dateType.currentSelected == dateType.data[0] || ((currentStartDate == "[object Date]"  && currentEndDate  == "[object Date]") && (new Date((this.projectPackagesAndStatus.selectedStartDate as any)) < new Date((this.projectPackagesAndStatus.selectedEndDate as any))))))
        dateValid = true
    //Assign proper validation
    if (!packageValid)
        this.projectPackagesAndStatus.currentValidationError = validationMessages.noOfferings
    else if (!statusValid)
        this.projectPackagesAndStatus.currentValidationError = validationMessages.noStatuses
    else if (!dateValid)
        this.projectPackagesAndStatus.currentValidationError = validationMessages.noDate

    if (packageValid && statusValid && dateValid)
    this.projectPackagesAndStatus.enableDownloadButton = true
    else
    this.projectPackagesAndStatus.enableDownloadButton = false
  }
  changeDate(typeOfDate: string)
  {
      if (typeOfDate == 'start')
      this.projectPackagesAndStatus.endDate.minDate = new Date(this.projectPackagesAndStatus.selectedStartDate)
      else
      this.projectPackagesAndStatus.startDate.maxDate = new Date(this.projectPackagesAndStatus.selectedEndDate)
      this.validateReportInput();
  }
  getTotalSelected(selection: string){
    if (selection == SELECT_TYPE_STATUS)
    return this.projectPackagesAndStatus.statusOfProjects.projectStatus.filter(_ => _.completed == true).length
    return this.projectPackagesAndStatus.packages.packageList.filter(_ => _.completed == true).length

  }
  getOnlySelected(selection: string){
    if (selection == SELECT_TYPE_STATUS)
    return this.projectPackagesAndStatus.statusOfProjects.projectStatus.filter(_ => _.completed == true)
    return this.projectPackagesAndStatus.packages.packageList.filter(_ => _.completed == true)
  }
  checkSelectedStatus(selectType: string)
  {
    this.validateReportInput()
    if (selectType == SELECT_TYPE_STATUS)
    return this.projectPackagesAndStatus.statusOfProjects.projectStatus.filter(_ => _.completed).length > 0 && !this.projectPackagesAndStatus.statusOfProjects.allCompleted
    return this.projectPackagesAndStatus.packages.packageList.filter(_ => _.completed).length > 0 && !this.projectPackagesAndStatus.packages.allCompleted

  }
  selectAllStatus(checked: boolean,selectType: string)
  {
    if (selectType == SELECT_TYPE_STATUS)
    {
      this.projectPackagesAndStatus.statusOfProjects.allCompleted = checked
      this.projectPackagesAndStatus.statusOfProjects.projectStatus.forEach(st => (st.completed = checked))
    }
    else
    {
      this.projectPackagesAndStatus.packages.allCompleted = checked
      this.projectPackagesAndStatus.packages.packageList.forEach(st => (st.completed = checked))
    }
    this.validateReportInput()
  }
  updateAllCompleted(selectType: string)
  {
    if (selectType == SELECT_TYPE_STATUS)
    this.projectPackagesAndStatus.statusOfProjects.allCompleted = this.projectPackagesAndStatus.statusOfProjects.projectStatus.every(_ => _.completed)
    else
    this.projectPackagesAndStatus.packages.allCompleted = this.projectPackagesAndStatus.packages.packageList.every(_ => _.completed)
    this.validateReportInput()
  }
  removeChip(chipName: string,selectType: string)
  {
    if (selectType == SELECT_TYPE_STATUS)
    {
      this.projectPackagesAndStatus.statusOfProjects.projectStatus.forEach(target => {
        if (target.status == chipName)
        target.completed = false
      })
      this.projectPackagesAndStatus.statusOfProjects.allCompleted = false
    }
    else
    {
    this.projectPackagesAndStatus.packages.packageList.forEach(target => {
      if (target.name == chipName)
      target.completed = false
    })
    this.projectPackagesAndStatus.packages.allCompleted = false
    }
  }
  convertToCamelCase(input){
    const words = (input == "HARD_DELETE" ? "DELETED" :input).toLowerCase().split('_');
    for (let i = 0;i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ')
  }
  scrollToTop()
  {
    setTimeout(_ => document.querySelector('#container_layout').scrollTo(0,0),300)
  }
}

import { CreateProjectService } from '@projects/create-project/src/app/create-project/create-project.service';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { BUS_MESSAGE_KEY } from 'src/app/shared/constants/message-bus';
import { PassGlobalInfoService } from 'src/app/shared/services/pass-project-global-info.service';
import { UserPreferenceService } from 'src/app/shared/services/user-preferences.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ProjectGlobalInfoModel } from '../../model/project-global-info.model';
// Changes for SSO Integration.
import { MessagingService } from '../../services/messaging.service';
import { CryptUtilService } from '../../services/crypt-util.service';
import { TAB_SAVE_CONST } from '../../constants/tab-change-save-dialog';
import { CommonDialogueBoxComponent } from '../common-dialogue-box/common-dialogue-box.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { IIDRService } from '@projects/iidr/src/app/iidr/iidr.service';
import { HostListener } from '@angular/core';
import { SitemapService } from '@projects/sitemap/src/app/sitemap/sitemap.service';
import { ToolsAcceleratorsControlsService } from '@projects/trainstops/src/app/services/tools-accelerators-controls.service';
import { UsersDataSharedService } from '@projects/user-management/src/app/user/service/users-data-shared.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { AdminManagementService } from '@projects/user-management/src/app/user/service/admin-management.service';
import { User } from '../../constants/ascend-user-info';
import { SystemNotificationService } from '../../services/system-notification.service';
import { SharedService as ProjectSharedService } from '@projects/shared-lib/src/public-api';
import { environment } from 'src/environments/environment';
import { CMSFileType } from '@projects/shared-lib/src/lib/utility/constants';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { AdobeAnalyticsService } from 'src/app/adobe-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import {DEFAULT_LANGUAGE} from '../../constants/language-constant';
import { ReleasePopupComponent } from '@projects/workspace/src/app/my-projects/list/release-popup/release-popup.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { TermsOfUseComponent } from 'src/app/base/components/welcome/terms-of-use/terms-of-use.component';
import { DELOITTE_WEB_PRIVACY } from 'src/app/base/constants/home-constant';
declare let Optanon: any;
import { GlobalInstancesService } from '@projects/shared-lib/src/lib/services/global-instances.service';
import { US_REGION } from '@projects/workspace/src/app/my-projects/utility/constants';
import { map } from 'rxjs/operators';


interface ApiResponse {
  status: number;
  data: [
    {
      releaseBannerDetails: [];
    }
  ];
  timestamp: string;
}

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  inputBoxVisible: boolean;
  view;
  tab: any = 'tab1';
  private subscriptions: Subscription[] = [];
  userData: User;
  url = 'https://graph.microsoft.com/v1.0/me';
  docsCount: number;
  docsCountTemp: number;
  projectGlobalInfo: ProjectGlobalInfoModel;
  summaryScreen: any;
  decPassword: string;
  lsProjectGlobalData: any = this.cryptoUtilService.getItem('projectGlobalInfo', 'LOCAL');
  selectedProject: any = this.cryptoUtilService.getItem('selectedProject', 'LOCAL');
  userInitials = '';
  projectPackageData: any;
  projectFunctionsData: any[] = [];
  activePackagesData: any[] = [];
  activePackages: any[] = [];
  activeFunctions: any[] = [];
  routedFrom;
  navigationSubscription: Subscription;
  callUserRelatedAPISubscription: Subscription;
  isUserRelatedAPICalled: boolean = false;
  userPrefPackage: any;
  userPrefFunction: any[] = [];
  userPrefPackageId: any;
  // userPrefPackage="Oracle ERP Cloud";
  // userPrefFunction="Cyber risk";
  userPrefActiveFunctions: any[] = [];
  //userPrefDialogRef: MatDialogRef<CommonDialogueBoxComponent>;
  datta: any;
  userDataForUserPreference: any;
  checkUserLogout: boolean = false;
  displayUserName: any;
  aboutScreen = false;
  createProjFormDirty: boolean = false;
  featuresData: any;
  canAccessAdminModule: boolean = false;
  activeOffering: any;
  selectedOffering: any;
  isContactMenuOpen: boolean = false;
  isServiceMenuOpen: boolean = false;
  isServiceMenuHover: boolean = false;
  contactDetails = [];
  isExternalUser: boolean;
  userEmailId = '';
  isNotificationMenuOpen: boolean = false;
  isNotifications: boolean = false;
  isUnseenNotificationPresent: boolean;
  showNotificationIcon: boolean;
  currentRoute: string = '';
  languageList = [];
  currentLanguageLocale:string;
  currentLanguage:string;
  currentUserEmailID = '';
  isLanguageShow: boolean = false;
  isHome: boolean = true;
  primaryRedirectionUrl: string = '';
  current_instance: string = US_REGION;
  globalInstances: any = [];
  releaseBanner=[]
  pinnedUserCount: number = 0;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.routedFrom = event.target.location.pathname;
    if (this.routedFrom != '/amplifier-exchange' && this.routedFrom != '/marketplace-solutions' && !this.routedFrom.includes('platform-admin')) {
      this.navigateToPageURL(this.routedFrom, 1);
    } else if (this.routedFrom.includes('/amplifier-exchange') && !this.routedFrom.includes('/marketplace-solutions')) {
      this.navigateToPageURL(this.routedFrom, 2);
    } else if (this.routedFrom.includes('/marketplace-solutions')) {
      this.navigateToPageURL(this.routedFrom, 3);
    } else if (this.routedFrom.includes('platform-admin')) {
      this.navigateToPageURL(this.routedFrom, 4);
    }
  }

  constructor(
    public sharedService: SharedService,
    public router: Router,
    private globalData: PassGlobalInfoService,
    private messagingBus: MessagingService,
    private userPreferences: UserPreferenceService,
    private cryptoUtilService: CryptUtilService, private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    public createProjServ: CreateProjectService,
    private iidrService: IIDRService,
    private sitemapService: SitemapService,
    private toolsAcceleratorsControlsService: ToolsAcceleratorsControlsService,
    private usersDataSharedService: UsersDataSharedService,
    private clipboard: Clipboard,
    private adminManagementService: AdminManagementService,
    private systemNotificationService: SystemNotificationService,
    private projectSharedService: ProjectSharedService,
    private adobeAnalyticService :AdobeAnalyticsService,
    private translate: TranslateService,
    private globalInstancesService: GlobalInstancesService,
  ) {
    this.getGlobalIntances();
    this.globalData.share.subscribe(x => this.projectGlobalInfo = x);
    this.userPreferences.getUserPrefData().subscribe(data => {
      this.userPrefPackage = data.packageName;
      this.userPrefFunction = data.functionName;
    });


  }

  getGlobalIntances() {
    this.globalInstancesService.globalInstances().then((res) => {
      this.globalInstances = res?.data?.instances;
      let host = location.host;
      this.globalInstances?.forEach(item => {
        if(item?.is_active) {
          if(item?.instance_name === US_REGION) {
            this.primaryRedirectionUrl = item?.application_url;
          }
          if(item?.application_url.includes(host)) {
            this.current_instance = item?.instance_name;
          }
        }
      });
      let currentGlobalData = {
        primaryRedirectionUrl: this.primaryRedirectionUrl,
        current_instance: this.current_instance
      }
      this.globalInstancesService.setCurrentGlobalInstance(currentGlobalData);
    });
  }

  getFeatureData() {
    this.sharedService.getFeaturesData(0).subscribe(res => {
      if (res) {
        this.featuresData = res;
        this.cryptoUtilService.setItem("Features-Data", this.featuresData?.data, "SESSION")
        this.toolsAcceleratorsControlsService.sendFeatureData(this.featuresData?.data);
      }
    }, (err) => { });
  }

  ngOnInit() {

    this.subscriptions.push(
      this.messagingBus.subscribe(BUS_MESSAGE_KEY.LANG_PREFERENCE, (data) => {
        this.languageList = data.languages.map(({ locale, languageNativeName,languageId }) => ({
          value: locale,
          label: languageNativeName,
          languageId
        }));
        this.currentLanguageLocale = data.lang.locale;
        this.currentLanguage = data.lang.languageNativeName;
        this.isLanguageShow = data.enabledFlag;
      })
    )

    this.systemNotificationService.getIsUnseenNotificationPresent().subscribe(res => {
      this.isUnseenNotificationPresent = res;
    })

    this.checkMenuAccess()

    this.navigationSubscription = this.createProjServ.getNavigationName.subscribe(routeName => {
      if (routeName === 1) {
        this.navigateToPageURL('/workspace/my-projects', 1);
      } else if (routeName == 2) {
        this.navigateToPageURL('/amplifier-exchange', 2)
      } else if (routeName == 3) {
        this.navigateToPageURL('/market-solution', 3)
      } else if (routeName == 'learnAbtAscend') {
        this.learnAbtAscend();
      } else if (routeName == 'siteMap') {
        this.siteMap();
      } else if (routeName == 'exploreMode') {
        this.lauchExploreAscend();
      } else if (routeName == 'home') {
        this.goToHome();
      }
    });
    this.routedFrom = this.router.url;
    this.iidrService.setTab().subscribe((response) => {
      if (response == 1) {
        this.navigateToPageURL('/workspace/my-projects', response);
      }
      else if (response == 0) {
        this.navigateToPageURL('/iidr', response);
      }
      else if (response == 6) {
        this.navigateToPageURL(`/sitemap/${this.projectGlobalInfo.projectId}`, response);
      }
      // to maintain the state of active link when navigation from specific trainstop activity
      else if (response == 7) {
        this.tab = 'tab1';
      }
    });

    // if (this.routedFrom != '/amplifier-exchange'
    // && this.routedFrom != '/marketplace-solutions'
    // && !this.routedFrom.includes('platform-admin'))
    let currentRoute = this.routedFrom.split('/')[1];
    if (currentRoute.includes('workspace')) {
      this.navigateToPageURL(this.routedFrom, 1);
    } else if (currentRoute.includes('amplifier-exchange')) {
      this.navigateToPageURL(this.routedFrom, 2);
    } else if (currentRoute.includes('marketplace-solutions')) {
      ['market-survey'].includes(this.routedFrom, 3)
    } else if (currentRoute.includes('solution-library')) {
      this.navigateToPageURL(this.routedFrom, 4);
    } else if (
      ['platform-admin', 'audit-log', 'content', 'methodology', 'document-repository', 'user-management', 'user-stories', 'business-case', 'velocity'].includes(currentRoute)
    ) {
      this.navigateToPageURL(this.routedFrom, 5);
    }
    if (this.lsProjectGlobalData) {
      this.globalData.updateGlobalData(this.lsProjectGlobalData);
    }
    if (this.selectedProject) {
      this.globalData.setProjectDetails(this.selectedProject);
    }
    // if(this.checkUserLogout)
    // {
    this.subscriptions.push(this.messagingBus
      .subscribe(BUS_MESSAGE_KEY.USER_DETAILS, (data) => {
        this.userData = data;
        this.userData.userId = data?.ssoUser?.userPrincipalName ?? data.userId;
        this.userInitials = data?.userName.split(' ').map((n) => n[0]).join('');
        this.displayUserName = data.userName;
        if (data.userName.includes(',')) {
          this.displayUserName = data.userName.replace(',', "");
        }
        this.currentUserEmailID = data?.projectDetails?.userId ?? data.userId;

        if (!['/', '/welcome', '/userselection'].includes(location?.pathname) && !this.isUserRelatedAPICalled) {
          this.callUserRelatedAPI();
        }

      }));
    this.checkUserLogout = false;


    this.globalData.share.subscribe(data => {
      this.isHome =  (this.router.url != '/welcome'  && this.router.url != '/maintenance' && this.router.url != '/about') ? false : true;

      this.view = data.viewMode;
      // if (data.viewMode == 'PROJECT') {
      //   this.docsCount = 0;
      //   this.docsCountTemp = 0;
      //   this.sharedService.getData(`${environment.BASE_URL}/project/summary/${data.projectId}`).subscribe(data => {
      //     for (let i of data[0].artifacts) {
      //       this.docsCount += i.artifactCount;
      //     }
      //     this.docsCountTemp = this.docsCount;
      //   })
      // }
    });

    // set package function filter selected flag
    let packageFunction = this.cryptoUtilService.getItem(BUS_MESSAGE_KEY.IIDR_FILTER + '_functionpackage_' +
      this.projectGlobalInfo.projectId, 'SESSION');
    if (packageFunction) {
      this.sharedService.filterSelected = true;
    } else {
      this.sharedService.filterSelected = false;
    }

    this.sharedService.docAddEvent.subscribe(data => {
      if (data) {
        if (data == 'Y') {
          this.docsCountTemp++;
        } else if (data == 'N') {
          this.docsCountTemp--;
        } else if (data == 'RESET' || data == 'FAILED') {
          this.docsCountTemp = this.docsCount;
        } else if (data == 'UPDATE') {
          this.docsCount = this.docsCountTemp;
        }
      }
    });
    this.getReleaseBanner()

    this.subscriptions.push(this.messagingBus.subscribe(BUS_MESSAGE_KEY.RELEASE_BANNER, (message) => {
      message && this.getReleaseBanner()
    }))



    // Tab post change
    this.sharedService.dataChangeEvent.subscribe(data => {
      if (data && data.type == 2 && data.source === TAB_SAVE_CONST.NAVIGATION_BAR) {
        this.router.navigate([`${data.data}`]);
      }
    });

    // this.sharedService.getTabs().subscribe(data => {
    //   this.sharedService.getIndex(data,this.router.url);
    // });

    // this.userPreferences.getUserPreferences().subscribe(response => {
    //   this.activePackages = response.data;
    //   this.activePackagesData = response.data.map(function (item) { return item.name; });

    // });

    // this.userPreferences.getUserpreferenceFunction().subscribe(response => {
    //   this.userPrefActiveFunctions = response.data;
    // });

    this.userPreferences.getUserPreferencesData(this.userData?.userId).subscribe(response => {
      if (response && response?.data) {
        response.data.forEach(ele => {
          this.userPrefPackage = ele.package;
          this.userPrefFunction = ele.functions;
          this.userPrefPackageId = ele.package_id;
          this.sendPackageData(this.userPrefPackage)
          this.sendFunctionData(this.userPrefFunction)
          this.sendPackageIdData(this.userPrefPackageId);
          this.sendParentPackageIdData(ele.parent_package_id);
          this.userPreferences.sendOfferingData(ele.parent_package_nm);
          this.userPreferences.sendLanguageId(ele.language_id);
        });
      }
      // else{
      //   if(this.router.url =='/welcome' && !(response?.data)){
      //     this.userPreference();
      //   }
      // }
    }, (error) => {
      console.log(error);
    });
    this.sendUserData();
    // }
    // else{
    //   this.authenticationService.logout();
    // }

    this.toolsAcceleratorsControlsService.getValue().subscribe((val) => {
      if (val) {
        this.getFeatureData();
      }
    });

    this.callUserRelatedAPISubscription = this.sharedService.callUserRelatedAPI().subscribe((val: boolean) => {
      if (this.callUserRelatedAPISubscription && val) {
        this.callUserRelatedAPI();
      }
    });

    this.subscriptions.push(
      this.sharedService.getUserPermissionDataUpdated().subscribe((data) => {
        this.isExternalUser = this.sharedService.getExternalUserData()?.isExternalUser;
        if(this.isExternalUser){
          let userId = JSON.parse(JSON.stringify(this.currentUserEmailID));
          this.userEmailId = userId?.split('@')[0]?.replace('_','@');
        } else {
          this.userEmailId= this.currentUserEmailID;
        }
      }));
    this.router.events
      .filter((event: any) => event instanceof NavigationEnd)
      .subscribe((event) => {
        this.adobeAnalyticService.setApplicationName("Ascend");
        this.adobeAnalyticService.setPageName(event.url);
        this.adobeAnalyticService.setUserId(this.userData.userId.toString());
        this.adobeAnalyticService.trackNavigationEvent();
      });
  }

  sendParentPackageIdData(parent_package_id: any) {
    this.userPreferences.sendOfferingIdData(parent_package_id)
  }

   /**
   * Fetches the release banner details from the shared service.
   * The method subscribes to the observable returned by the service,
   * and updates the `releaseBanner` property with the banner details
   * from the response.
   */
    getReleaseBanner = () =>
      this.sharedService.getReleaseBannerDetails().subscribe((response:ApiResponse) => {
        this.releaseBanner = response?.data[0]?.releaseBannerDetails
            });

  callUserRelatedAPI() {
    this.getUserRoleAssociationDetails()
    this.getContacts();
    this.getFeatureData();
    this.isUserRelatedAPICalled = true;
    this.showNotificationIcon = true;
  }

  checkMenuAccess() {
    this.globalData.share.subscribe(data => {
      this.canAccessAdminModule = data?.userRoleInfo?.role ? true : false;
    });
  }

  getUserRoleAssociationDetails() {
    if (this.projectGlobalInfo)
      this.usersDataSharedService.updateUserRoleAssociation(this.projectGlobalInfo, this.userData.userId, this.globalData, true)
  }

  searchClicked() {
    this.inputBoxVisible = !this.inputBoxVisible;
  }

  checkFormDirty() {
    if (this.createProjServ.getCreateFormData() !== undefined && this.router.url == '/createproject' &&
      (this.createProjServ.getCreateFormData().clientForm.dirty ||
        this.createProjServ.getCreateFormData().projectForm.dirty)) {
      return this.createProjFormDirty = true;
    } else {
      return this.createProjFormDirty = false;
    }
  }

  lauchExploreAscend() {
    if (this.checkFormDirty()) {
      this.createProjServ.showConfrmatnPopup.next('exploreMode');
    } else {
      this.projectGlobalInfo = new ProjectGlobalInfoModel();
      this.projectGlobalInfo.viewMode = 'EXPLORE';
      this.projectGlobalInfo.projectId = '0';
      this.projectGlobalInfo.uniqueId = '0';
      this.projectGlobalInfo.projectName = '';
      this.projectGlobalInfo.clientName = '';
      this.projectGlobalInfo.clientUrl = '';
      this.globalData.updateGlobalData(this.projectGlobalInfo);
      this.globalData.setProjectDetails({
        project: {},
        viewmode: this.projectGlobalInfo.viewMode
      });
      this.iidrService.setIsDataExplore.next(true);
      this.cryptoUtilService.setItem('Previous-Session-Function-Selected-IIDR-Project', '', 'SESSION');
      this.router.navigate(['/iidr']);
      this.iidrService.getTab(0);
      this.usersDataSharedService.updateUserRoleAssociation(this.projectGlobalInfo, this.userData.userId, this.globalData);
    }
  }

  siteMap() {
    if (this.checkFormDirty()) {
      this.createProjServ.showConfrmatnPopup.next('siteMap');
    } else {
      this.projectGlobalInfo = new ProjectGlobalInfoModel();
      this.projectGlobalInfo.viewMode = 'EXPLORE';
      this.projectGlobalInfo.projectId = '0';
      this.projectGlobalInfo.uniqueId = '0';
      this.projectGlobalInfo.projectName = '';
      this.projectGlobalInfo.clientName = '';
      this.projectGlobalInfo.clientUrl = '';
      this.globalData.updateGlobalData(this.projectGlobalInfo);
      this.globalData.setProjectDetails({
        project: {},
        viewmode: this.projectGlobalInfo.viewMode
      });

      this.router.navigate([`/sitemap/${this.projectGlobalInfo.projectId}`]);
      this.sitemapService.setProjectInfo(null);
      this.cryptoUtilService.setItem("Session-Sitemap-Filter", "", "SESSION");
      this.cryptoUtilService.setItem("Session-Sitemap-Filter-Attributes", "", "SESSION");
      this.cryptoUtilService.setItem("Session-Sitemap-Filter-Amplifiers", "", "SESSION");
      this.cryptoUtilService.setItem('Previous-Session-Function-Selected-IIDR-Project', null, 'SESSION');
      this.sitemapService.getData(true);
      this.sitemapService.setIsSitemapExplore.next(true);
      this.iidrService.getTab(6);
    }
  }

  contactUs() {
    window.open('mailto:AscendNerveCenter@deloitte.com');
  }

  viewRoadmap() {
    const fileNameWithoutExtension = environment.ASCEND_ROADMAP_FILENAME.split('.')[0];

    this.projectSharedService.downloadDoc(fileNameWithoutExtension, environment.ASCEND_ROADMAP_FILENAME,
      environment.CMS_CONTAINER_NAMES.UTILITY, CMSFileType.Utility);
  }

  learnAbtAscend() {
    if (this.checkFormDirty() && !this.isExternalUser) {
      this.createProjServ.showConfrmatnPopup.next('learnAbtAscend');
    } else if (this.isExternalUser) {
      let learAboutAscend = 'https://www2.deloitte.com/us/en/pages/operations/solutions/technology-enabled-transformation-solutions.html';
      window.open(learAboutAscend);
    } else {
      this.aboutScreen = true;
      this.router.navigate(['/welcome']).then(res => {
        this.sharedService.openAboutPage(this.aboutScreen);
      });
    }
  }

  ascendKX() {
    window.open('https://resources.deloitte.com/sites/consulting/sandd/delivery_excellence/Pages/Deloitte_Ascend.aspx');
  }

  methodology() {
    window.open('https://hrtech.deloitte.com/momentum#/subpage/home');
  }

  offering() {
    window.open('https://consulting.deloitteresources.com/offerings/enterprise-operations/oracle/Pages/Home.aspx');
  }

  menuClosed() {
    // this.supportExpansion.close();
    // this.relatedLinkExpansion.close();
  }

  emitEvent(identifier, route) {
    if (this.sharedService.toggled === 'TOGGLED') {
      let dataChangeEventObj = {
        source: identifier,
        data: route,
        type: 1
      }
      this.sharedService.dataChangeEvent.emit(dataChangeEventObj);
    } else {
      this.redirectToCreateProject(route, '')
    }

  }

  navigateToSummary() {
    if (this.view !== 'EXPLORE' && this.sharedService.filterSelected) {
      this.emitEvent(TAB_SAVE_CONST.NAVIGATION_BAR, '/project/summary');
    }
  }

  goToHome() {
    if (this.checkFormDirty()) {
      this.createProjServ.showConfrmatnPopup.next('home');
    } else {
      this.emitEvent(TAB_SAVE_CONST.NAVIGATION_BAR, '/welcome');
    }
  }

  ngOnDestroy() {
    this.callUserRelatedAPISubscription?.unsubscribe();
    this.subscriptions?.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  navigateToPage(route) {
    // this.globalData.scrollToViewWelcome("About");
    this.router.navigateByUrl(route);
  }

  navigateToPageURL(url, targetValue) {
    let prevTab = this.tab;
    if (this.checkFormDirty()) {
      this.createProjServ.showConfrmatnPopup.next(targetValue);
    } else {
      if (targetValue == 1) {
        this.tab = 'tab1';
      } else if (targetValue == 2) {
        this.tab = 'tab2';
      } else if (targetValue == 3) {
        this.tab = 'tab3';
      } else if (targetValue == 4) {
        this.tab = 'tab4';
      } else if (targetValue == 5) {
        this.tab = 'tab5';
      } else {
        this.tab = '';
      }
      // if (targetValue == 0 || targetValue == 5) {
      //   this.tab = '';
      // }

      if (targetValue == 5 || this.router.url === '/welcome') {
        this.redirectToCurrentHostURL(url, prevTab)
      } else {
        this.redirectToCreateProject(url, prevTab)
      }


    }
  }

  redirectToCurrentHostURL(url, prevTab) {
    if (this.router.url === '/workspace/my-projects' && url === '/workspace/my-projects') {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([url]).then((res) => {
          if (res === false) {
            this.tab = prevTab;
          }
        });
      });
    } else {
      this.router.navigate([url]).then((res) => {
        if (res === false) {
          this.tab = prevTab;
        }
      });
    }
  }

  redirectToCreateProject(url, prevTab) {
    if(this.current_instance === US_REGION || this.router.url.includes('/psg/')) {
      this.redirectToCurrentHostURL(url, prevTab)
      return true;
    }

    if(this.current_instance !== US_REGION && !this.router.url.includes('/psg/')) {
      window.location.href = `${this.primaryRedirectionUrl}/${url}`;
    }
  }

  navigateToExploreMode(route) {
    this.projectGlobalInfo = new ProjectGlobalInfoModel();
    this.projectGlobalInfo.viewMode = 'EXPLORE';
    this.projectGlobalInfo.projectId = '0';
    this.projectGlobalInfo.uniqueId = '0';
    this.projectGlobalInfo.projectName = '';
    this.projectGlobalInfo.clientName = '';
    this.projectGlobalInfo.clientUrl = '';
    this.cryptoUtilService.sessionClear();
    this.globalData.updateGlobalData(this.projectGlobalInfo);
    // this.globalData.scrollToViewWelcome("Explore");
    this.router.navigateByUrl(route);
    this.globalData.setProjectDetails({
      project: {},
      viewmode: this.projectGlobalInfo.viewMode
    });
  }

  OnClickBtn() {
    this.router.navigate(['/welcome', 'reimagineOracleCloud']);
  }

  userPreference() {
    this.dialog.open(CommonDialogueBoxComponent, {
      data: {
        from: 'USER_PREFERENCES',
        userId: this.userData?.userId,
        title: this.translate.instant("Navigation.Menu.UserPreferences.Dialog.Title"),
        messageText: this.translate.instant("Navigation.Menu.UserPreferences.Dialog.MessageText"),
        buttonText: 'Navigation.Menu.UserPreferences.Dialog.Button.Apply',
        buttonContinueClass: true,
        cancelBtn: "Navigation.Menu.UserPreferences.Dialog.Button.Cancel",
        buttonCancelClass: true,
        activePackages: this.activePackages,
        activeFunctions: this.activeFunctions,
        projectPackage: this.projectPackageData,
        selectedPackage: this.activePackagesData,
        selectedFunctions: this.projectFunctionsData,
        userPrefPackage: this.userPrefPackage,
        userPrefFunction: this.userPrefFunction,
        selectedOffering: this.selectedOffering,
        activeOffering: this.activeOffering

      },
      maxWidth: '37vw',
      width: '37vw',
      panelClass: 'custom-dialog-container'
    });
  }

  sendUserData() {
    this.userPreferences.sendUserId(this.userData?.userId);
  }

  sendPackageData(p: any) {
    this.userPreferences.sendPackageData(p);
  }

  sendFunctionData(f: any) {
    this.userPreferences.sendFunctionData(f);
  }

  sendPackageIdData(p: any) {
    this.userPreferences.sendPackageIdData(p);
  }

  logout() {
    this.authenticationService.logout();
    //this.router.navigate(['/logout']);
    this.refirectToLogout();

  }

  refirectToLogout() {
    //this.router.navigate(['/logout']);
  }

  cookies() {
    window.open('https://cookienotice.deloitte.com');
  }

  contactMenuClosed(){
    this.isContactMenuOpen = false;
  }

  serviceMenuClosed(){
    this.isServiceMenuOpen = false;
  }

  serviceMenuOpen(){
    this.isServiceMenuOpen = true;
  }

  openGeneralSupport(){
    window.open('https://deloitteus.service-now.com/sp?id=sc_cat_item&sys_id=0027aca34f3bd7c433c77bcd0210c72d&table=sc_cat_item&sysparm_value=AE')
  }

  openReportIssue(){
    window.open('https://deloitteus.service-now.com/sp?id=sc_cat_item&sys_id=b1fc31234fbb5bc433c77bcd0210c72a&table=sc_cat_item&sysparm_value=AE')
  }

  /**
   * OpenActivationCoachingServices: method will open
   * new service now window for "Activation & Coaching Services".
  */
  openActivationCoachingServices = () => window.open(environment.ACTIVATION_COACHING_SERVICE_URL)

  contactMenuOpen(){
    this.isContactMenuOpen = true;
  }

  notificationMenuClosed() {
    this.isNotificationMenuOpen = false;
    if (this.isUnseenNotificationPresent) {
      this.systemNotificationService.seenNotifications().subscribe((res) => {
        if (res) {
          this.systemNotificationService.setNotificationAction(true);
          this.systemNotificationService.setIsUnseenNotificationPresent(false);
        }
      })
    }
  }

  notificationMenuOpened() {
    this.isNotificationMenuOpen = true;
    this.systemNotificationService.getIsRedirectLinkClicked().subscribe((res) => {
      if (res) {
        this.trigger.closeMenu();
        this.systemNotificationService.setIsRedirectLinkClicked(false);
      }
    })
  }

  getContacts(region?){
    this.contactDetails = [];
    this.pinnedUserCount = 0;
    this.adminManagementService.getContactList(
      this.userData?.ssoUser?.userPrincipalName ?? this.userData.userId,
      region
    ).pipe(
      map(res => res.data?.map(contacts => ({
        ...contacts,
        users: contacts?.users?.map(user => ({
          ...user,
          getUserName: this.getUserName(user?.first_name, user?.last_name, user?.pinnedStatus)
        }))
      })))
    ).subscribe(
      resp => {
        if (resp.length) {
          this.contactDetails = resp;
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  getUserName(first_name: string, last_name: string, pinnedStatus: number) : string {
    if (pinnedStatus) {
      this.pinnedUserCount++;
    }
    return (first_name && first_name.toLowerCase() !== 'null' ? first_name : '') + ((last_name && last_name.toLowerCase() !== 'null') ? (first_name && first_name.toLowerCase() !== 'null' ? ' ' : '') + last_name : '')
  }


languageSelect(event) {
  this.currentLanguageLocale = event.value;
  this.currentLanguage = event.label;
  this.translate.use(event.value);
  this.userPreferences.sendLanguageId(event.languageId);
  const updatePreferredLanguageBody = {
    userId: this.userData.projectDetails?.data?.userId,
    languageId: event.languageId
}
  this.sharedService.updatePreferredLanguage(updatePreferredLanguageBody).subscribe(
    (result:any) => {
      console.log(result);
      this.sharedService.setUpdatedLangId(updatePreferredLanguageBody?.languageId);
      this.sharedService.setCurrentLanguage(updatePreferredLanguageBody?.languageId);
      return;
    }, (error) => {
      console.log(error);
    }
  );
}

navigateToTerms() {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.data = {
    title: this.translate.instant("LandingPage.TermsOfUse.Title"),
    primarybutton: this.translate.instant("LandingPage.TermsOfUse.Button.OK"),
  };

  const dialogRef = this.dialog.open(TermsOfUseComponent, dialogConfig);

  dialogRef.afterClosed().subscribe((data) => {
    if (data === "true") {
      return;
    }
  });
}

navigateToPolicy() {
  let URLPolicy = DELOITTE_WEB_PRIVACY;
  if (URLPolicy) {
    window.open(URLPolicy);
  }
}

cookieSettings() {
  Optanon?.ToggleInfoDisplay();
}

goToReleasePage(){
  this.dialog.open(ReleasePopupComponent, {
    width: "900px",
    panelClass: "deactivate-dialog-container",
    // minHeight: 'calc(100vh - 90px)',

    height: "auto",
    disableClose: false,
    data: {
      releaseBannerDetails: `${this.releaseBanner[0]?.banner_content}`.replace(
        "\n",
        ""
      ),
      startDate: new Date(this.releaseBanner[0]?.start_date),
      endDate: new Date(this.releaseBanner[0]?.end_date),
    },
  });
}

}
